import Vue from 'vue'
import axios from '@/plugins/axios'
import Vuex from 'vuex'
import router from '@/router'
Vue.use(Vuex)

const t = localStorage.getItem('token')
// document.cookie = `token=${t}; path=/; HttpOnly`

// 用来存储数据
const state = {
  userInfo: {},
  token: t,
  authed: !(t == null || t.length == 0),
}

function clear(state) {
  state.token = ''
  state.userInfo = {}
  state.authed = false
  localStorage.setItem('token', '')
  router.push('/sign-in')
}

axios.get('/api/platform/user/userInfo')
  .then(res => {
    if (res.code === 0) {
      state.authed = true
      state.userInfo = res.data
      state.token = t
      router.push('/')
      localStorage.setItem('token', 'valid')
    } else {
      clear(state)
    }
  })

// 响应组件中的事件
const actions = {

}
// 操作数据
const mutations = {
  setUserInfo(state, value) {
    state.userInfo = value
  },
  setToken(state, value) {
    state.token = value
    state.authed = true
    localStorage.setItem('token', value)
  },
  clear,
}
// 用来将state数据进行加工
const getters = {

}
// 新建并暴露store
export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters
})
