import axios from 'axios'

//配置基础url、超时时间、post请求头
// axios.defaults.baseURL = 'http://xxx.xx.xx.xxx:xxxx'
axios.defaults.timeout = 5 * 60 * 1000
axios.defaults.withCredentials = true
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

//------------------请求拦截-------------------//
//------------在发送请求之前做些什么------------//
axios.interceptors.request.use(config => {
    return config;
}, err => {
    return err;
})

//------------------响应拦截-------------------//
//-------------对响应数据做点什么-------------//
axios.interceptors.response.use(res => {
    return res ? res.data : res;
}, err => {
    console.log(err)
    //打印完整的错误信息
    console.log("响应拦截错误完整信息=>",err.response)
    //也可以在这里对不同的错误码做不同的展示处理
    throw err;
})

export default axios