<template>
	<!-- Main Sidebar -->
	<component :is="navbarFixed ? 'a-affix' : 'div'" :offset-top="top">

		<!-- Layout Header -->
		<a-layout-header>
			<a-row type="flex">

				<!-- Header Breadcrumbs & Title Column -->
				<a-col :span="24" :md="6">

					<!-- Header Breadcrumbs -->
					<!--
					<a-breadcrumb>
						<a-breadcrumb-item><router-link to="/">Home</router-link></a-breadcrumb-item>
						<a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
					</a-breadcrumb>
					-->
					<!-- / Header Breadcrumbs -->

					<!-- Header Page Title -->
					<!-- <div class="ant-page-header-heading">
						<span class="ant-page-header-heading-title">{{ this.$route.name }}</span>
					</div> -->
					<!-- / Header Page Title -->

				</a-col>
				<!-- / Header Breadcrumbs & Title Column -->

				<!-- Header Control Column -->
				<a-col :span="24" :md="18" class="header-control">

					<!-- Header Control Buttons -->
					
					<a-dropdown hidden :trigger="['click']" overlayClassName="header-notifications-dropdown"
						:getPopupContainer="() => wrapper">
						<a-badge :count="notificationsData.length">
							<a class="ant-dropdown-link" @click="e => e.preventDefault()">
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
										fill="#111827" />
									<path d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z" fill="#111827" />
								</svg>
							</a>
						</a-badge>

						<a-list item-layout="horizontal" class="header-notifications-list" :data-source="notificationsData"
							slot="overlay">
							<a-list-item slot="renderItem" slot-scope="item">
								<a-list-item-meta>
									<template #description>
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd"
												d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071L12.1213 13.5355C12.5118 13.9261 13.145 13.9261 13.5355 13.5355C13.9261 13.145 13.9261 12.5118 13.5355 12.1213L11 9.58579V6Z"
												fill="#111827" />
										</svg>
										<span>{{ item.time }}</span>
									</template>
									<a slot="title" href="#">{{ item.title }}</a>
									<a-avatar v-if="item.img" slot="avatar" shape="square" :src="item.img" />
									<a-avatar v-else shape="square" slot="avatar" v-html="item.svg" />

								</a-list-item-meta>
							</a-list-item>
						</a-list>
					</a-dropdown>
					
					<a-button type="link" class="sidebar-toggler"
						@click="$emit('toggleSidebar', !sidebarCollapsed), resizeEventHandler()">
						<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
							<path
								d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
						</svg>
					</a-button>
					
					<a hidden class="btn-sign-in" @click="logout">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
								fill="#111827" />
						</svg>
						<span>注销登录</span>
					</a>
					<a-button :loading="check.loading" type="primary" @click="checkIn" :disabled="check.checkIned">{{ checkInTip }}</a-button>
					<!-- / Header Control Buttons -->

					<!-- Header Search Input -->
					<!--
					<a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Type here…"
						@search="onSearch" :loading='searchLoading'>
						<svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
								fill="#111827" />
						</svg>
					</a-input-search>
					-->
					<!-- / Header Search Input -->

				</a-col>
				<!-- / Header Control Column -->

			</a-row>
		</a-layout-header>
		<!--  /Layout Header -->

	</component>
	<!-- / Main Sidebar -->
</template>

<script>

const notificationsData = []

const checkInTipMap = {
	false: '每日签到',
	true: '已签到'
}

export default ({
	props: {
		// Header fixed status.
		navbarFixed: {
			type: Boolean,
			default: false,
		},

		// Sidebar collapsed status.
		sidebarCollapsed: {
			type: Boolean,
			default: false,
		},

		// Header notifications data.
		notificationsData: {
			type: Array,
			default: () => notificationsData,
		},
	},
	data() {
		return {
			// Fixed header/sidebar-footer ( Affix component ) top offset.
			top: 0,
			check: {
				checkIned: false,
				loading: false,
			},

			// Search input loading status.
			searchLoading: false,

			// The wrapper element to attach dropdowns to.
			wrapper: document.body,
		}
	},
	methods: {
		resizeEventHandler() {
			this.top = this.top ? 0 : -0.01;
			// To refresh the header if the window size changes.
			// Reason for the negative value is that it doesn't activate the affix unless
			// scroller is anywhere but the top of the page.
		},
		onSearch(value) {
		},
		logout(e) {
			e.preventDefault()
			this.$axios.post('/api/platform/user/logout')
				.then(res => {
					if (res.code === 0) {
						this.$store.commit('clear')
					}
				})
		},
		checkIn() {
			this.check.loading = true
			this.$axios.post('/api/platform/core/checkin')
				.then(res => {
					if (res.code == 0) {
						this.$message.info(`成过领取了${res.data.integral}积分`)
						this.$store.state.userInfo.integral += res.data.integral
						this.$store.state.userInfo.hasCheckIn = true
						this.check.checkIned = true
					} else {
						this.$message.error(res.msg)
					}
					this.check.loading = false
				})
		}
	},
	mounted: function () {
		// Set the wrapper to the proper element, layout wrapper.
		this.wrapper = document.getElementById('layout-dashboard')
		this.check.checkIned = this.$store.state.userInfo?.hasCheckIn == true
	},
	created() {
		// Registering window resize event listener to fix affix elements size
		// error while resizing.
		window.addEventListener("resize", this.resizeEventHandler);
	},
	computed: {
		checkInTip() {
			return checkInTipMap[this.check.checkIned]
		}
	},
	destroyed() {
		// Removing window resize event listener.
		window.removeEventListener("resize", this.resizeEventHandler);
	},
})

</script>
